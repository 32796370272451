import React, { useContext, useRef } from 'react';
import styled from 'styled-components';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Splash from '../components/Splash';
import Reboot from '../style/reboot';
import Global from '../style/global';
import ModalContext from '../store/modalContext';
import Modals from '../modals';
import { colors } from '../consts/style';
import { layoutTypes } from '../types/propTypes';
import useElementDimensions from '../hooks/useElementDimensions';


const SiteWrapper = styled.div`
  background: white;
`;

const Layout = ({ children, location }) => {
  const { open } = useContext(ModalContext);

  function renderModal() {
    if (!open) return null;
    const ModalComponent = Modals[open];
    return <ModalComponent />;
  }
  return (

    <SiteWrapper id="top">
      <Reboot />
      <Global />
      {location.pathname == '/' && <Splash/>}
      
        <Header location={location} />
    
     
      {children}
      {renderModal()}
      <Footer  location={location} />
    </SiteWrapper>
   
  );
};

Layout.propTypes = layoutTypes;

export default Layout;
