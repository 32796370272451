/* eslint-disable quotes */
import bp from './breakpoints';

const mQueryPoint = {
  mobile: `${bp.mobile}px`,
  tabletSmall: `${bp.tabletSmall}px`,
  tablet: `${bp.tablet}px`,
  desktop: `${bp.desktop}px`,
};

export const bz = `
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  `;

export const boxShadow = `
box-shadow : 0px 3px 6px #00000029;
`
export const font = {
  h1: `
        font-size: 3.5rem;
        text-transform: uppercase;
        font-weight:200;
        line-height:30px;
        letter-spacing:1.25px;
        margin:2.4rem 0;

        @media screen and (max-width: ${mQueryPoint.tablet}) {
            font-size: 3.5rem;
        }
    `,

  p:  `
        font-size: 2rem;
        line-height:30px;
        letter-spacing:.5px;
        font-weight:normal;


        @media screen and (max-width: ${mQueryPoint.tablet}) {
            font-size: 1.8rem;
        }
    `, 

    style1:  `
        font-size: 1.8rem;
        line-height:22px;
        text-transform:uppercase;
        letter-spacing:3.6px;
        font-weight:600;
    `,

    styleEquipeMembreTitle:  `
        font-size: 2.2rem;
        line-height:25px;
        text-transform:none;
        letter-spacing:.55px;
        font-weight:700;
    `,

    styleProjetSubTitle:  `
        font-size: 2.2rem;
        line-height:30px;
        text-transform:uppercase;
        letter-spacing:.55px;
        font-weight:600;
    `,

    styleButton:  `
        font-size: 1.8rem;
        line-height:22px;
        text-transform:uppercase;
        letter-spacing:2.7px;
        font-weight:500;
    `,



  button: `
        font-weight: normal;
        font-size: inherit;
    `,

};

export const colors = {
  dark: '#464342',
  light: '#fff',
  greyLight: '#CECECE',
  buttonHover: '#6d6967',
  cta:'#57877A',
};

export const z = {
  modalBackground: `z-index: 100000;`,
  modal: `z-index: 200000;`,
};

import { css } from 'styled-components';

// Creates up & down media queries for your breakpoints
// *** Usage ***
// import { mq } from "**/style.js"
// export const StyledComponent = styled.div`
// ${media.tablet`
//   display: flex;
// `}
// ${media.mobile_up`
//   display: none;
// `}
//`

export const mq = Object.keys(bp).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media screen and (max-width: ${bp[label]}px) {
      ${css(...args)};
    }
  `;

  acc[`${label}_up`] = (...args) => css`
    @media screen and (min-width: ${bp[label]}px) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});
