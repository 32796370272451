// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bla-bla-js": () => import("./../../../src/pages/bla-bla.js" /* webpackChunkName: "component---src-pages-bla-bla-js" */),
  "component---src-pages-components-js": () => import("./../../../src/pages/components.js" /* webpackChunkName: "component---src-pages-components-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-ecohabiter-2023-js": () => import("./../../../src/pages/ecohabiter2023.js" /* webpackChunkName: "component---src-pages-ecohabiter-2023-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-projets-js": () => import("./../../../src/pages/projets.js" /* webpackChunkName: "component---src-pages-projets-js" */),
  "component---src-pages-ressources-js": () => import("./../../../src/pages/ressources.js" /* webpackChunkName: "component---src-pages-ressources-js" */),
  "component---src-pages-succes-js": () => import("./../../../src/pages/succes.js" /* webpackChunkName: "component---src-pages-succes-js" */),
  "component---src-templates-element-prive-js": () => import("./../../../src/templates/ElementPrive.js" /* webpackChunkName: "component---src-templates-element-prive-js" */),
  "component---src-templates-projet-js": () => import("./../../../src/templates/Projet.js" /* webpackChunkName: "component---src-templates-projet-js" */)
}

