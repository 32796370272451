import React from 'react';
import styled from 'styled-components';
import { Link, graphql, useStaticQuery  } from 'gatsby';
import { colors, mq } from '../consts/style';
import { SectionTitle,  BackToTopLink,
  BackToTopWrapper, ArrowUpIcon } from './Elements';

import BackgroundImage from 'gatsby-background-image';
import LogoSVG from '../images/e-cohabiter_logo_white.svg'


/*export const Wrapper = styled.div`*/


export const FooterWrapper = styled(BackgroundImage)`

  display: flex;
  flex-direction: column;
  padding-bottom: 10.1rem;
  padding-top: 10.3rem;
  color: white;
  background: transparent;
  justify-content: center;
  align-items: center;
  position:relative;
  background-size: cover;
  
`;

const FooterInner = styled.div`
 padding: 0 2rem;
 display:block;
 width: 100%;
 text-align:center;
 img {
  display:none;
 }
 ${mq.tabletSmall_up`
   text-align:left;
  display: flex;
  width: 120rem;
  max-width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  `}
 
 ${mq.tablet_up`
  img {
    display:block;
  }
  `}
  ul {
    list-style: none;
    padding:0;
      li {
         display:inline-block;
         padding:.5rem;
        ${mq.tabletSmall_up`
            display:block;
             padding:0;

          `
      }
  }

`;

const FooterRight = styled.div`
  flex-basis: 33.3%;
  flex-grow: 1;
  
    ${mq.tabletSmall_up` text-align: right;`}
  line-height:30px;
  h3 {
    text-transform:uppercase;
    font-size:2rem;
  }
  .nav_footer_right li a {

    color: white;
    &:hover {
      color: white;
    }
 
  }
`;

const FooterLeft = styled.div`
  flex-basis: 33.3%;
  flex-grow: 1;
  line-height:30px;
`;

const FooterBottom = styled.div`
  padding: 0 2rem;
  margin-top:4.4rem;
  color:${colors.greyLight};
  a {
     color:${colors.greyLight};
  }
`;

const Footer= ({ location }) => {

  const today = new Date();
  const year = today.getFullYear();

  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "iStock-591400212_pierre-noire_Nastco_cr.jpg" }) {
          childImageSharp {
            fluid(quality: 50, maxWidth: 1920, maxHeight: 550) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  // Set ImageData + overlay semi transparent

  const backgroundFluidImageStack = [
    data.desktop.childImageSharp.fluid,
    `linear-gradient(#2d2b2b9c, #2d2b2b9c)`,
   
  ].reverse()


  return (
    <FooterWrapper
  
      fluid={backgroundFluidImageStack}
       
    >

       <BackToTopWrapper>
        <BackToTopLink  to={location.pathname+'#top'} className="anchorlink" title="Retour en haut">
         <ArrowUpIcon width="70px"/>Retour en haut
        </BackToTopLink>
      </BackToTopWrapper>
      <FooterInner>
         {/* <SectionTitle>CONTACT</SectionTitle>*/ }
        
      </FooterInner>
      
      <FooterInner>
        <FooterLeft>

         <p> <b>e-cohabiter</b>, SASU d'architecture<br/>
          Hôtel d'entreprises - ECOSITE<br/>
          Place Michel Paulus<br/>
          26400 EURRE<br/><br/>
          04 26 52 11 09<br/>
         
          contact@e-cohabiter.com
          </p>
        </FooterLeft>
           <img src={LogoSVG} width="320" alt="E-cohabiter"/>
        <FooterRight>
          <h3>Liens</h3>
          <ul className="nav_footer_right">
            <li>
              <Link to="/">accueil</Link>
            </li>
            <li>
              <Link to="/bla-bla">bla-bla</Link>
            </li>
            <li>
              <Link to="/projets">projets</Link>
            </li>
            <li>
              <Link to="/ressources">ressources</Link>
            </li>
            <li>
              <Link to="/contact">contact</Link>
            </li>
           
          </ul>
        </FooterRight>
      </FooterInner>
      <FooterBottom>
        © Copyright {year} e-cohabiter | Tous droits réservés | <Link to="/mentions-legales">Mentions légales</Link>
      </FooterBottom>
    </FooterWrapper>
  );
};

export default Footer;
