import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Headroom from 'react-headroom';
import Img from 'gatsby-image';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { colors, boxShadow, mq } from '../consts/style';
import { spin } from '../style/animations';
import { headerTypes } from '../types/propTypes';
import { MenuAltRight } from '@styled-icons/boxicons-regular';
import { XCircle } from '@styled-icons/bootstrap';
import {/* config,*/ useSpring, animated } from 'react-spring'
import LogoSVG from '../images/e-cohabiter_logo_196.svg'

const Wrapper = styled.div`
  background-color: white;
  ${boxShadow};
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .5rem 2rem;
`;

const HeaderInner = styled.div`
  display: flex;
  width: 120rem;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
      flex-wrap: wrap;
`;

const Image = styled(Img)`
 
`;

const MainMenu = styled.ul`
  display: none;
  
${mq.tablet_up`
  display: flex;
   list-style: none;
  justify-self: flex-start;
  margin: 0;
  padding:0;
  padding-left: 4.8rem;
  text-transform: uppercase;
 `}

  li a {
          font-weight: 600;

  }
`;

const SecondaryMenu = styled.ul`
  display: none;
 
  ${mq.tablet_up`
    display: flex;
    list-style: none;
    margin: 0;
    margin-left: auto;
    text-transform: uppercase;
   `}
  li a { 
    color:${colors.greyLight}; 
    letter-spacing:3.6px;
    font-size:1.8rem; 
    font-weight:600;
    &:hover{
      color:${colors.greyLight}; 
    }
    &:hover:before {
      content:none;
    }
  }
`;

const MenuItem = styled.li`
  position: relative;
  letter-spacing:3.6px;
  font-size:1.8rem;
  color: ${colors.dark};
  &:hover {
    color: ${colors.buttonHover};
  }
  &:not(:last-child):after {
    content: ' | ';
   
    padding: 0 1.5rem 0 1rem;
    color:${colors.greyLight};
  }
`;

const LinkHeader = styled(Link)`
  position:relative;
  color: ${colors.dark};
  &:hover {
    color: ${colors.dark};
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
      left: ${props => (props.selected ?"50%": 0 )};
    bottom: -.45rem;
    background-color: ${colors.dark};
    border-radius:3px;
    width: 30px;
    height: 3px;
    opacity: ${props => (props.selected ? 1: 0 )};
    transform:translateX(${props => (props.selected ?"-50%": 0 )});
  }

   &:hover:before,  &.active:before  {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    border-radius:8px;
    bottom: -.45rem;
    background-color: ${colors.dark};
    width: 30px;
    height: 3px;
    transform:translateX(-50%);
    transition: all 0.25s;   
    opacity: 1;
  }
`;

const LogoLink = styled(LinkHeader)`
  img {vertical-align: unset;}
  
  &:after {
    ${mq.tablet_up`
        content: '';
        height:100px;
        display: inline-block;
        height: 55px;
        margin-left: 4.8rem;
        width: 1px;
        background-color: ${colors.greyLight};
     `}
  }

  &:hover:before,  &.active:before 
  {
    content:none;
  }
`;

const BurgerMenu = styled.button`
  position:absolute;

  right:1rem;
  display:block;
  border:none;
  background:white;
  &:focus {
    outline:none;
  }
  ${mq.tablet_up` 
   display:none;
 `}
`;

const CloseMenuMobile = styled.button`
  position:absolute;
  top:1rem;
  right:1rem;
  display:block;
  border:none;
  background:white;
  &:focus {
    outline:none;
  }
`;

/*MENU MOBILE*/
const StyledMenuAltRight = styled(MenuAltRight)`
  color:${colors.dark};
`
const StyledXCircle = styled(XCircle)`
  color:${colors.dark};
`


const MenuMobile = styled(animated.div)`
  
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  z-index:9;
  transform: translate3d(0,-100%,0);
`
const ContentMobile = styled(animated.div)`

  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  nav {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
  }
`

const ItemMobileNav = styled(animated.div)`
  font-size:3rem;
  padding:.5rem;
  margin-bottom:.5rem;
  letter-spacing:3.6px;
  text-transform:uppercase;
`

const headerQuery = graphql`
  {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(height: 80, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export default function Header({ location }) {
  const data = useStaticQuery(headerQuery);
  const [mobileNavOpen, setMobileNavOpen] = useState(false) // mobile menu closed by default
  const MenuAnimation = useSpring({
  native: true,
  to: { opacity: mobileNavOpen ? 1 : 0, transform: mobileNavOpen ? 'translate3d(0,0,0)' : 'translate3d(0,-100%, 0)', backgroundColor: mobileNavOpen ? '#fffffff5' : '#fff'},
  })
  /**
   * Oftentimes we'll have different UI state
   * based on the router location.  Do it here.
   */
  /*useEffect(() => console.log(location), [location]);*/

  return (
    <> 
    <Headroom>
    <Wrapper>
      <HeaderInner>
        <LogoLink to="/" title="Retour à l'accueil" >
          <img src={LogoSVG} alt="Logo e-cohabiter" height="45px"/>
        </LogoLink>
        <MainMenu>
          <MenuItem>
            <LinkHeader to="/bla-bla" activeClassName="active">Bla-bla</LinkHeader>
          </MenuItem>
          <MenuItem>
            {' '}
            <LinkHeader to="/projets" activeClassName="active">Projets</LinkHeader>
          </MenuItem>
          <MenuItem>
            <LinkHeader to="/ressources" activeClassName="active">Ressources</LinkHeader>
          </MenuItem>
          <MenuItem>
            {' '}
            <LinkHeader to="/contact" activeClassName="active">Contact</LinkHeader>
          </MenuItem>
        </MainMenu>
        <SecondaryMenu>
         {/*   <MenuItem> <LinkHeader to="#">privé</LinkHeader> </MenuItem>
          <MenuItem><LinkHeader to="#">EN</LinkHeader></MenuItem> */}
        </SecondaryMenu>
        <BurgerMenu  onClick={() => {setMobileNavOpen(true)}}
    aria-label="Navigation"><StyledMenuAltRight title="Navigation" size="48"/></BurgerMenu>
      </HeaderInner>
    </Wrapper>
     </Headroom>
    <MenuMobile style={ MenuAnimation}> 
        <ContentMobile>
          <nav>
          <ItemMobileNav onClick={() => {setMobileNavOpen(!mobileNavOpen)}}>
             <LinkHeader to="/" >Accueil</LinkHeader>
          </ItemMobileNav>
          <ItemMobileNav onClick={() => {setMobileNavOpen(!mobileNavOpen)}} >
            <LinkHeader to="/bla-bla" >Bla-bla</LinkHeader>
          </ItemMobileNav>
          <ItemMobileNav onClick={() => {setMobileNavOpen(!mobileNavOpen)}} >
            <LinkHeader to="/projets">Projets</LinkHeader>
          </ItemMobileNav>
          
          <ItemMobileNav onClick={() => {setMobileNavOpen(!mobileNavOpen)}} >
            <LinkHeader to="/ressources">Ressources</LinkHeader> 
          </ItemMobileNav>
          <ItemMobileNav onClick={() => {setMobileNavOpen(!mobileNavOpen)}} >
            <LinkHeader to="/contact">Contact</LinkHeader>  
          </ItemMobileNav>
      {/*    <ItemMobileNav onClick={() => {setMobileNavOpen(!mobileNavOpen)}}>
            <LinkHeader to="/">English </LinkHeader> 
          </ItemMobileNav>*/} 
             
          </nav>
          <CloseMenuMobile 
          onClick={() => {setMobileNavOpen(false)}} 
           ><StyledXCircle size="48"  title="Fermer le menu" /></CloseMenuMobile>
        </ContentMobile>    
      </MenuMobile>  
      </>

  );
}

Header.propTypes = headerTypes;
