import styled from 'styled-components';
import { Link } from 'gatsby';
import { font, colors, z, bz, mq } from '../consts/style';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { fadeInUp, slideIn } from '../style/animations';
import BackgroundBackToTop from "../images/back_to_top_background.jpg";
import { Plus, ArrowDownShort, ArrowUpShort, ArrowLeftShort, ArrowRightShort } from '@styled-icons/bootstrap';
import { Apps } from '@styled-icons/ionicons-solid';

export const Spacer = styled.div`
  height: 5rem;
  width: 100%;
  display: block;
  position: relative;
`;


export const Banner = styled.div`
  height: ${ p => p.height};
  width: calc(100% + 4rem);
  overflow: hidden;
  display: block;
  position: relative;
  margin-bottom: 2rem;
  margin-right: -2rem;
  margin-left: -2rem;
  margin-top: -2rem;

  .gatsby-image-wrapper {
    height:${ p => p.height};
    filter:grayscale(1);
  }

`;

export const PageWrapper = styled.div`
  min-height: calc(100vh - 10rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  padding: 2rem;
  color: ${colors.dark};
`;

export const PageInner = styled.div`
  width: 120.4rem;
  max-width: 100%;

  button {
    ${font.button};
    background: ${colors.dark};
    border: none;
    color: white;
    padding: 0.35em 0.7em;
    margin: 0;
    margin-top: 0.7em;
    margin-right: 0.7em;
    &:hover {
      text-decoration: none;
      background: ${colors.buttonHover};
    }
  }
`;

export const PageTitle = styled.h1`
  ${font.h1}
`;

export const SectionTitle = styled.h2`
  ${font.h1}
`;

export const NavigationBottom = styled.div`
margin-top:9rem;
margin-bottom:5rem;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
`;

export const Text2Col = styled.div`
   columns: 2 300px;
  /* column-gap:10.4rem;*/
    column-gap:8%;  
   ${font.p}
`;

export const Grid2Col = styled.div`
  display: block;
  ${mq.tablet_up`
    display:grid;
    grid-template-columns: 1fr 1fr;  
   /* column-gap:10.4rem; */  
    column-gap:8%;   
 `}
`;

export const Grid3Col = styled.div`
  display: block;
  
  ${mq.tabletSmall_up`
    display:grid;
     align-items: start;
    grid-template-columns: 1fr 1fr;  
    column-gap:4rem;   
    
    
 `}
  ${mq.tablet_up`
    display:grid;
     align-items: start;
    grid-template-columns: 1fr 1.32fr 1fr;  
    column-gap:4rem;   
    
    
 `}
`;

export const LinkToGoogleMap = styled.a`
  display: block;
  background: ${colors.dark};
  color:white;
  text-align:center;
  margin-top:1.7rem;
  padding:1rem 0.5rem;
   ${font.styleButton};
     transition:all .25s ease-out;
   &:hover{
    color:white;
    background-color:${colors.buttonHover};  
   }
`;


export const EquipeMembreTitle = styled.h3`
  margin-top:1.5rem;
  ${font.styleEquipeMembreTitle}  
  ${mq.tabletSmall_up`
    max-width:100px;
    text-align:center;
  `}
`;





export const ProjetsWrapper = styled.div`
  display:grid;
  grid-template-columns: ${props => (props.type === "homepage" ? '1fr ' : '1fr' )};
  grid-gap:5rem;
  ${mq.tabletSmall_up`
    grid-template-columns: ${props => (props.type === "homepage" ? '1fr 1fr' : '1fr 1fr 1fr' )};
  `}
`;

export const ProjetLink = styled.div` 
  .title {
    text-transform:uppercase;
    letter-spacing:0;
    font-weight:600;
    margin-bottom:0rem;
    margin-top:1.5rem;
    line-height:1;
    font-size: 2.2rem;
  }

  .nav-helper { 
    display:flex;
    align-items: center;
    width:100%;
    font-size:1.8rem;
    letter-spacing:.9px;
    line-height:22px;
    text-transform:uppercase;
    font-weight:600;
    margin-bottom:1.4rem;
    span { transition: padding .25s ease!important;
        ${bz};}
  }

  .description {
   line-height:30px;
   display:block;
  }

  img {
    filter: grayscale(1);
    transition: transform .35s ease!important;
    ${bz};
  }
  
  a {
    color: ${colors.dark};
    &:hover {
      .nav-helper {
          font-weight:700;
          span {
            padding:0 .5rem;
            transition: all .25s ease!important;
            ${bz};
          }
          svg {
              transform:scale(1.2); 
              transition: all .25s ease!important;
              ${bz};
          }
      }
      text-decoration: none;
      h3 {color:${colors.cta}}
      img {
        filter: grayscale(0);
        transition:filter 1s ease!important;
        transform: scale(1.1) ;
        transition: transform .25s ease!important;
        ${bz};
      }
    }
  }
`;



export const FilterList = styled.div`
  display:inline-flex;
  flex-wrap: wrap;
  margin-left:1rem;
  margin-bottom:1.6rem;
`;


export const FilterItem = styled.span`
  position:relative;
  cursor:pointer;
  padding: 0;
  margin: 0 2rem 2rem;
  margin-left: 2rem;
  ${font.style1};
  letter-spacing:.9px;

  &:not(:last-child):after {
    position:absolute;
    content: ' | ';
    padding: 0 1rem 0 2rem;
    color:${colors.greyLight};  
  }

  /*&:hover{
    color:${colors.buttonHover};
  }*/

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: ${props => (props.selected ?"50%": 0 )};
    bottom: -.45rem;
    background-color: ${colors.dark};
    width: 30%;
    height: 2px;
    opacity: ${props => (props.selected ?1: 0 )};
    transform:translateX(${props => (props.selected ?"-50%": 0 )});
  }

  &:hover:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    bottom: -.45rem;
    background-color: ${colors.dark};
    width: 30%;
    height: 2px;
    transform:translateX(-50%);
    transition: all 0.25s;   
    opacity:1;
  }
`;

export const AppsIcon = styled(Apps)`
  color: ${colors.dark};
  background: white;
  border-radius: 100%;
  padding: 10px;
  border: 2px solid ${colors.dark};
  width: 50px;
  margin-left: 1rem;
  transition: all 0.25s;
  ${bz};
`;

export const PlusIcon = styled(Plus)`
  color: ${colors.dark};
  background: white;
  border-radius: 100%;
  padding: 4px;
  border: 2px solid ${colors.dark};
  width: 50px;
  margin-left: 1rem;
  transition: all 0.25s;
  ${bz};
`;

export const ProjectsListLink = styled(Link)`
  display: inline-block;
  position: relative;

 /* &:before {
    content:"";
    position:relative;
    right:0;
    border:1px solid ${colors.dark};
    width:75px;
    z-index:0;
    display: inline-block;
    transform:translateX(0);
    transition: all 0.25s;
    ${bz};
  }

  &:hover:before {
   transform:translateX(100%);
   opacity: 0;
   transition: all 0.25s;
    ${bz};
  }*/

  svg {
    position: relative;
    z-index:0;
    margin-left:2rem;
      overflow: visible;
  }

  &:hover svg {
    padding: 7px;
    color: ${colors.light};
    border: 3px solid ${colors.dark};
    background: ${colors.dark};
    transition: all 0.25s;
    ${bz};
  }


  span {
    ${font.p};
    margin-top:1rem;
   
    font-weight:500;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    opacity:0;
    transform:translateX(-80%);
    transition: .25s;
    ${bz};
  }

  &:hover span {
    width: auto;
    transform:translateX(-100%);
    background-color:white;
    opacity:1;
    overflow: visible;
    margin-right:1rem;
    padding-left:1rem;
    transition: 0.4s;
    ${bz};
  }
`;

/* anciennes versions design précédent */
export const SeeMoreLink = styled(Link)`
  position:relative;
  color: ${colors.dark};
  margin-bottom: 2rem;
  margin-top: 2rem;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;

  span {  
    ${font.p};
    margin-left:1rem;
    font-weight:500;
    transform:translateX(-100%);
    transition:all .5s;
    opacity:0;
  }

  &:hover span {
   
    transform:translateX(0);
     opacity:1;
  }
  
  svg {
    z-index:2;
    margin-left:1rem;
    margin-right:1rem;
  }

  &:hover svg {
    padding: 0;
    transform:scale(1.2);
    color: ${colors.light};
    border: 3px solid ${colors.dark};
    background: ${colors.dark};
    transition: all 0.25s;
    ${bz};
  }
  &:before {
    content:"";
    margin-right:1rem;
    height:2px;
    background:${colors.dark};
    width:125px;
    z-index:1;
    display: inline-block;
  }

   &:after {
    content:"";
    margin-left: 2rem;
    width: 125px;
    display: inline-block;
    height:2px;
    background:${colors.dark};
    z-index: 1;
    position: absolute;
    left: 195px;
  }

  &:hover:after {
     opacity:0;
     width:0;
     transition: all 0.35s;
  }
`;

export const SeeMoreLink2 = styled(Link)`
  display: flex;
    width: 100%;
    height: 85px;
    margin-bottom: 2rem;
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
    text-align: center;

  &:before, &:after {
    content: '';
    border-top: 2px solid;
    margin: 0 10px 0 0;
    flex: 0 0 150px;

  }

 &:hover svg {
    padding: 0;
    transform:scale(1.2);
    color: ${colors.light};
    border: 3px solid ${colors.dark};
    background: ${colors.dark};
    transition: all 0.25s;
    ${bz};
  }

  &:hover:after {
    content: attr(data-hover);
    ${font.p};
    font-weight:500;
    opacity: 1;
    width:auto;
    z-index:1;
    border:0;
    animation: ${slideIn} 0.35s;
    transition: all 0.35s;
    ${bz};
    
  }

  svg {
    z-index:2;
    margin-left:1rem;
    margin-right:1rem;
  }


  span {
    ${font.p};
   /* margin-left:1rem;*/
    font-weight:500;
    transform:translateX(-100%);
    transition:all .35s;
    font-size:0;
  }
`;
/* fin anciennes versions */

export const SeeMoreLink3 = styled(Link)`
    border: solid 1px #fff;
    display: inline-block;
    padding: 7px;
    position: relative;
    height: 85px;
    margin-bottom: 2rem;
    margin-top: 2rem;
    

  &:before {
    content:"";
    margin-right:1rem;
    position:relative;
    left:0;
    height:2px;
    background:${colors.dark};
    width:75px;
    z-index:0;
    display: inline-block;
  }

   &:after {
    content:"";
    margin-left:1rem;
    position:relative;
    right:0;
    height:2px;
    background:${colors.dark};
    width:75px;
    z-index:0;
    display: inline-block;
    transform:translateX(0);
    transition: all 0.25s;
    ${bz};
  }

 

  &:hover:after {
   transform:translateX(-100%);
   opacity: 0;
   transition: all 0.25s;
    ${bz};
  }

  svg {
    position: relative;
   /* z-index:1;*/
    margin-left:2rem;
    margin-right:2rem;
     overflow: visible;
  }

  &:hover svg {
    padding:  ${props => (props.icon === "menu_projet" ? '9px' : '0' )};
    transform: scale(1.2);
    color: ${colors.light};
    border: 3px solid ${colors.dark};
    background: ${colors.dark};
    transition: all 0.25s;
    ${bz};
  }


  span {
    ${font.p};
    margin-top:1rem;
    font-weight:500;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    opacity:0;
    transform:translateX(-5%);
    transition: .25s;
    ${bz};
  }

  &:hover span {
    width: auto;
    transform:translateX(0);
    background-color:white;
    z-index:3;
    opacity:1;
    overflow: visible;
    margin-left:1rem;
      transition: 0.4s;
    ${bz};
  }
`;

export const ArrowDownIcon = styled(ArrowDownShort)`
  color: ${colors.dark};
  padding: 0.75rem;
  background: white;
  border-radius: 100%;
  border: 2px solid ${colors.dark};
  width: 75px;
  margin-bottom: 1rem;
  transition: all 0.25s;
  ${bz};
`;

export const ArrowDownLink = styled(Link)`
  color: ${colors.dark};
  margin-bottom: 2rem;
  margin-top: 2rem;
  margin-left: 3rem;
  vertical-align: middle;
  text-align: center;
  text-transform: uppercase;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${font.style1}
  font-weight:500;
  &:hover {
      letter-spacing: 0.5rem;
      font-weight:600;

  }
  &:hover svg {
    color: ${colors.light};
    border: 3px solid ${colors.dark};
    background: ${colors.dark};
    transition: all 0.25s;
    ${bz};
  }
`;

export const ArrowUpIcon = styled(ArrowUpShort)`
  color: ${colors.dark};
  padding: 0.75rem;
  background: white;
  border-radius: 100%;
  border: 2px solid ${colors.dark};
  width:75px;
  margin-top: 1rem;
  transition: all 0.35s;
  ${bz};
`;

export const ArrowUpLink = styled(Link)`
  color: ${colors.dark};
  margin-bottom: 2rem;
  margin-top: 2rem;
  width:100px;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${font.style1}
    font-weight:500;
  &:hover {
      letter-spacing: 0.5rem;
      font-weight:600;
  }

  &:hover svg {
    color: ${colors.light};
    border: 3px solid ${colors.dark};
    background: ${colors.dark};
    transition: all 0.15s;
    ${bz};
  }
`;

export const ArrowLeftIcon = styled(ArrowLeftShort)`
  color: ${colors.dark};
  padding: 0;
  background: white;
  border-radius: 100%;
  border: 2px solid ${colors.dark};
  width:50px;
  transition: all 0.35s;
  ${bz};
`;

export const ArrowLeftLink = styled.div`
  color: ${colors.dark};
  text-align: center;
  vertical-align: middle;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    transform:scale(1.1);
    transition: all 0.35s;
    ${bz};
  }

  &:hover svg {
    color: ${colors.light};
    border: 3px solid ${colors.dark};
    background: ${colors.dark};
    transition: all 0.15s;
    ${bz};
  }
`;

export const ArrowRightIcon = styled(ArrowRightShort)`
  color: ${colors.dark};
  padding: 0;
  background: white;
  border-radius: 100%;
  border: 2px solid ${colors.dark};
  width:50px;
  transition: all 0.35s;
  ${bz};
`;

export const ArrowRightLink = styled.div`
  color: ${colors.dark};
  text-align: center;
  vertical-align: middle;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
       transform:scale(1.1);
  }

  &:hover svg {
    color: ${colors.light};
    border: 3px solid ${colors.dark};
    background: ${colors.dark};
    transition: all 0.15s;
    ${bz};
  }
`;

export const BackToTopWrapper = styled.div`
  margin: 0 auto;
  position:absolute;
  top:-5.2rem;
`;



export const BackToTopLink = styled(AnchorLink)`

    text-transform: uppercase;
    font-size:1.8rem;
    font-weight:600;
    letter-spacing:2.7px;
    color: ${colors.greyLight};
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.15s;
    ${bz};
    svg {
      width:7.5rem;
      height:7.5rem;
      transition: all 0.15s;
       ${bz};
    }

    :hover {
      color: ${colors.light};
      font-weight:700;
      letter-spacing:3.6px;
     
      transition: all 0.25s;
      ${bz};
      svg {
        color: white;
        background-color: #000000;
         background-image: linear-gradient(#00000030, #00000030), url(${BackgroundBackToTop});

        border: 4px solid white;
        transform:translateY(-3px) scale(1.1);
        transition: all 0.25s;
        ${bz};
      }
    }

`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  ${z.modalBackground};
`;

export const ModalInner = styled.p`
  background: white;
  margin: auto;
  color: ${colors.dark};
  position: relative;
  max-height: 90%;
  /*height: 25rem;*/
  width: 90%;
  max-width: 600px;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  animation: ${fadeInUp} 0.3s;
  ${bz};
 
  ${z.modal};
  
`;

