
import "@fontsource/dosis";
import "@fontsource/dosis/200.css";
import "@fontsource/dosis/500.css";
import "@fontsource/dosis/600.css";
import "@fontsource/dosis/700.css";
import { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle`

html { font-family: 'Dosis', sans-serif; font-feature-settings: 'dlig', 'zero', 'ss01', 'cv05', 'cv10';}

/*overflow hidden pour ne pas avoir de marge dans swiper full screen (projet.js)*/
html,
body {
  overflow-x: hidden;
}

@supports (font-variation-settings: normal) {
  html { font-family: 'Dosis var', sans-serif; }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration:none;
	&:hover {
		text-decoration:none;
		color:inherit;
	}
}

::selection {
  background: #f1f1f1; /* WebKit/Blink Browsers */
}


`;

export default Global;
