import React, {useRef, useState, useEffect} from 'react';
import styled from 'styled-components';
import {  graphql, Link , useStaticQuery} from 'gatsby';
import _map from 'lodash/map';
import { colors, boxShadow, mq, bz } from '../consts/style';
/*import Slider1 from './Slider/slider';*/
// import Swiper core and required components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, EffectFade } from 'swiper';
import styleSwiper from 'swiper/swiper.scss';
import styleNavigation from 'swiper/components/navigation/navigation.scss';
import styleEffectFade from 'swiper/components/effect-fade/effect-fade.scss';
console.log({ styleSwiper,styleNavigation, styleEffectFade  })
import { ArrowDown } from '@styled-icons/bootstrap';
import Img from 'gatsby-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import LogoSVG from '../images/logo-vector.svg'
import {  ArrowLeftLink, ArrowRightLink, ArrowLeftIcon, ArrowRightIcon  } from './Elements';

// install Swiper components
SwiperCore.use([Navigation, EffectFade]);


const headerQuery = graphql`
  {
    slides: datoCmsHomepage {
      projetsHomepage {
          slug
          titre
          id
          lieu
          imagePrincipale {
            fluid(maxWidth: 1980, forceBlurhash: false, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }    
      }
    }
  }
`;

const Wrapper = styled.div`
  background-color: #f6f6f6;
  height: calc(100vh - 4rem);
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  margin:2rem;
  position:relative;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  background: transparent;
  z-index: 1;
  width: 90%;
  ${mq.tabletSmall_up`
    width: auto;
  `}
 
`;

const LogoWrapper = styled.div`
  padding: 3.7rem 6rem;
  width:100%;
  text-align:center;
  background:white;
    ${boxShadow}
`;

const Logo = styled(Img)`

`;

const Slideshow = styled.div`
  position:absolute;
  top:0;
  left:0;
  background-color:#bfbfbf;
  width:100%;
  height:100%;

  .swiper-button-prev {
    top:unset;
    bottom:4.5rem;
    left: 4.2rem;
    font-family: swiper-icons;
    display:none;
  }
  .swiper-button-next {
    top:unset;
    bottom:4.5rem;
    left: calc(4.2rem + 50px + 1.1rem);
        display:none;
  }
`;
/*
const Slide = styled.div`
  width:100%;
  position:relative;
  height:calc(100vh - 3rem);
  background:white;
`;*/
/*
const SwiperSlideshow = styled.div`
  position:absolute;
  top:0;
  left:0;
  background-color:#bfbfbf;
  width:100%;
  height:100%;
`;*/


const ArrowLeftLinkNav = styled(ArrowLeftLink)`
  position:absolute;
  bottom:4.5rem;
  left: 1.2rem;
  ${mq.tabletSmall_up`
    left: 4.2rem;
  `}
  z-index:1;
  cursor:pointer; 
`;

const ArrowRightLinkNav = styled(ArrowRightLink)`
  position:absolute;
  bottom:4.5rem;
  left: calc(1.2rem + 50px + 1.1rem);
  ${mq.tabletSmall_up`
  left: calc(4.2rem + 50px + 1.1rem);
  `}
  z-index:1;
  cursor:pointer;
`;

const ImgProjet =styled(Img)`
  height:100%;
`

const GradientOverlay = styled.div`
  position:absolute;
  bottom:0;
  height:250px;
  width:100%;
  background:transparent linear-gradient(180deg, #00000000 0%, #00000099 100%) 0% 0% no-repeat padding-box;
`

const TitreProjet = styled.a`
  position:absolute;
  bottom:6.8rem;
  left: calc(1.2rem + 50px + 1.1rem + 50px + 2.2rem);
  ${mq.tabletSmall_up`
  left:  calc(4.2rem + 50px + 1.1rem + 50px + 2.2rem);
  `}
  font-size:2.4rem;
  margin-bottom: 0;
  letter-spacing: 1.2px;
  font-weight: 500;
  color:white;
  &:hover, &:focus {
    font-weight:700!important;
    color:white;
  }

`



const LieuProjet =  styled.span`
  position:absolute;
  bottom:4.1rem;
  left: calc(1.2rem + 50px + 1.1rem + 50px + 2.2rem);
  ${mq.tabletSmall_up`
  left:  calc(4.2rem + 50px + 1.1rem + 50px + 2.2rem);
  `}
  color:white;
  font-size:1.6rem;
   font-weight: 500;
  letter-spacing:.8px;
`;

const NavigationPrimary = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  
  list-style: none;
  justify-content: center;
  align-items: center;
  margin-top: .5rem;
  background-color:white;
  display:none;
  text-transform: uppercase;
  ${boxShadow}
  ${mq.tabletSmall_up`
    height:5rem;
    display:flex;
  `}

 
`;

const MenuItem = styled.li`
  position: relative;

  &:not(:last-child):after {
    content: '|';
    padding: 0 2.4rem;
    color:${colors.greyLight};
  }
   &:last-child:after {
    content: '';
    padding: 0 1.5rem 0 1rem;
  }
`;

const LinkHeader = styled(Link)`
  letter-spacing:0.36rem;
  color: ${colors.dark};
  font-weight:600;
  line-height:22px;
  position: relative;
  &:hover { color: ${colors.dark};}
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -.45rem;
    background-color: ${colors.dark};
    width: 30px;
    height: 3px;
    opacity: 0;
    transform:translateX(0);
     border-radius:2px;
  }
   &:hover:after,  &.active:after  {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    bottom: -.45rem;
    border-radius:2px;
    background-color: ${colors.dark};
    width: 30px;
    height: 3px;
    transform:translateX(-50%);
    transition: all 0.25s;   
    opacity: 1;
  }

`;

const GoToContentWrapper = styled.div `
  width: 100px;
  height: 50px; /* as the half of the width */
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border: 1px solid white;
  border-bottom: 0;
  background-color:white;
  text-align:center;
  z-index:1;
  position: absolute;
  bottom: 0;
  right: -1rem;
  ${mq.tabletSmall_up`
  right: auto;
  `}
 
  transition: all 0.25s;
    ${bz};
  &:hover {
    transform:scale(1.2);
     transition: all 0.25s;
    ${bz};
  }
`;

const ArrowDownIcon = styled(ArrowDown)`
  color: ${colors.dark};
  padding-top: 0.9rem;
  background: transparent;
  width: 50px;
`;




const NavSecondary = styled.div `
  z-index:2;
  position: absolute;
  top: 3rem;
  right:3rem;
  display:flex;
`;

const NavSecondaryItem = styled(Link) `
  background-color:#fff;
  color:${colors.dark};
  opacity:0.6;
  padding: 0.3rem 0.8rem 0.2rem 0.8rem;
  margin-left:0.5rem;
  font-size:1.6rem;
  font-weight:500;
  line-height:2rem;
  letter-spacing:0.16rem;
  text-transform:uppercase;

`;


const Splash = () => {
  const data = useStaticQuery(headerQuery);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const slidesSwiper = [];
  data.slides.projetsHomepage.map( ( projet, i) =>   
    slidesSwiper.push  (      
        <SwiperSlide key={`slide_${i}`}>
          <ImgProjet fluid={projet.imagePrincipale.fluid}/>
          <GradientOverlay/>
          <TitreProjet href={`/projets/${projet.slug}`} title={projet.titre}> 
              {projet.titre} 
          </TitreProjet>
          <LieuProjet>{projet.lieu}</LieuProjet>
        </SwiperSlide>
    )
  )
  
  


  return (
    <Wrapper>
     {/* <Slideshow>
       <Slider1 usage="homepage">
        {
        data.slides.projetsHomepage.map( ( projet) => {
          var slug = `/projets/${projet.slug}`
          return (      
          <Slide key={projet.id}>
            <ImgProjet fluid={projet.imagePrincipale.fluid}/>
            <GradientOverlay/>
            <TitreProjet to={slug} title={slug}> 
                {projet.titre} 
            </TitreProjet>
            <LieuProjet>{projet.lieu} {projet.id} {slug}</LieuProjet>
          </Slide>
          )
        }
        )
        }
        </Slider1>
      </Slideshow>
    */}
      <Slideshow>
      <Swiper
          effect="fade"
          slidesPerView={1}
          loop={1}
          style={{ height: '100%' }}
          navigation
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.update();
          }} 
         /*  onSlideChange={() => {console.log('slide change'); console.log(prevRef.current );console.log(nextRef.current );}}*/
         /* onInit={(swiper) => {
            console.log(swiper);
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.update();
          }}*/

          >
        { slidesSwiper /*
          data.slides.projetsHomepage.map( ( projet, i) => 
           
            (      
            <SwiperSlide key={`slide_${i}`}>
              <ImgProjet fluid={projet.imagePrincipale.fluid}/>
              <GradientOverlay/>
              <TitreProjet to={`/projets/${projet.slug}`} title={projet.titre}> 
                  {projet.titre} 
              </TitreProjet>
              <LieuProjet>{projet.lieu}</LieuProjet>
            </SwiperSlide>
            )
          
          )*/
        }
      
        <ArrowLeftLinkNav ref={prevRef} >
          <ArrowLeftIcon title="Précédent"/>
        </ArrowLeftLinkNav>
         <ArrowRightLinkNav ref={nextRef} >
          <ArrowRightIcon title="Suivant"/>
        </ArrowRightLinkNav>
        </Swiper>
      </Slideshow>
      <InnerWrapper>
         {/* <NavSecondary>
          <NavSecondaryItem to="#">Privé</NavSecondaryItem>
          <NavSecondaryItem to="#">EN</NavSecondaryItem>
        </NavSecondary> */}
        <LogoWrapper>
          <img src={LogoSVG} alt="Logo e-cohabiter"/> 
        </LogoWrapper>
        <NavigationPrimary>
          <MenuItem>
            <LinkHeader to="/bla-bla">Bla-bla</LinkHeader>
          </MenuItem>
          <MenuItem>
            {' '}
            <LinkHeader to="/projets">Projets</LinkHeader>
          </MenuItem>
          <MenuItem>
            <LinkHeader to="/ressources">Ressources</LinkHeader>
          </MenuItem>
          <MenuItem>
            {' '}
            <LinkHeader to="/contact">Contact</LinkHeader>
          </MenuItem>
        </NavigationPrimary>
      </InnerWrapper>
       <GoToContentWrapper>
        <AnchorLink to="/#top-content" title="C'est parti !" ><ArrowDownIcon /></AnchorLink>
       </GoToContentWrapper>
       
    </Wrapper>
  );
};

export default Splash;
